import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon, faHome, faEnvelope, faDiagramProject } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode === 'true' ? true : false;
  });

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem('darkMode', newDarkMode);
    if (newDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  };

  return (
    <nav className="bg-gray-800 p-4 flex justify-between items-center">
      <div className="text-white text-lg">
        <Link className='font-bold px-4' to="/">Life Stages</Link>
        <Link to="/" className="px-2"> <FontAwesomeIcon icon={faHome} /> Home</Link>
        <Link to="/about" className="px-2"><FontAwesomeIcon icon={faDiagramProject} />A propos</Link>
        <Link to="/contact" className="px-2"><FontAwesomeIcon icon={faEnvelope} />Contact</Link>
      </div>
      <div className="relative">
        <button
          className="text-white focus:outline-none"
          onClick={toggleDarkMode}
        >
          <FontAwesomeIcon icon={darkMode ? faSun : faMoon} />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
