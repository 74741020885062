import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBirthdayCake, faVenusMars, faGlobe, faEdit, faTrash, faPaperPlane, faFolderOpen} from '@fortawesome/free-solid-svg-icons';
import { faPlug } from '@fortawesome/free-solid-svg-icons/faPlug';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';

const LifeStages = () => {
  const [birthdate, setBirthdate] = useState('');
  const [age, setAge] = useState(null);
  const [gender, setGender] = useState('all');
  const [country, setCountry] = useState('all');
  const [stages, setStages] = useState([]);
  const [recurringEvents, setRecurringEvents] = useState([]);
  const [oneTimeEvents, setOneTimeEvents] = useState([]);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const calculateAge = useCallback((birthdate, gender, country) => {
    const today = new Date();
    let age = today.getFullYear() - birthdate.getFullYear();
    const m = today.getMonth() - birthdate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
      age--;
    }
    setAge(age);
    fetchLifeStages(age, gender, country);
    fetchRecurringEvents(age, gender, country);
    fetchOneTimeEvents(age);
  }, []);

  useEffect(() => {
    const savedBirthdate = localStorage.getItem('birthdate');
    const savedGender = localStorage.getItem('gender');
    const savedCountry = localStorage.getItem('country');
    if (savedBirthdate) {
      const birthdate = new Date(savedBirthdate);
      setBirthdate(birthdate.toISOString().split('T')[0]);
      calculateAge(birthdate, savedGender, savedCountry);
      setIsFormVisible(false);
    } else {
      setIsFormVisible(true);
    }
    if (savedGender) setGender(savedGender);
    if (savedCountry) setCountry(savedCountry);
  }, [calculateAge]);

  const validateForm = () => {
    const errors = {};
    if (!birthdate) errors.birthdate = 'Date de naissance est obligatoire';
    if (!gender) errors.gender = 'Le genre est obligatoire';
    if (!country) errors.country = 'Le pays est obligatoire';
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    const birthdate = new Date(e.target.birthdate.value);
    localStorage.setItem('birthdate', birthdate.toISOString());
    localStorage.setItem('gender', gender);
    localStorage.setItem('country', country);
    setBirthdate(birthdate.toISOString().split('T')[0]);
    calculateAge(birthdate, gender, country);
    setIsFormVisible(false);
  };

  const handleReset = () => {
    localStorage.removeItem('birthdate');
    localStorage.removeItem('gender');
    localStorage.removeItem('country');
    setBirthdate('');
    setAge(null);
    setGender('all');
    setCountry('all');
    setStages([]);
    setRecurringEvents([]);
    setIsFormVisible(true);
  };

  const fetchLifeStages = async (age, gender, country) => {
    setLoading(true);
    const response = await fetch('./lifeStages.json');
    const data = await response.json();
    const filteredStages = data.stages.filter(stage =>
      age >= stage.minAge &&
      age <= stage.maxAge &&
      (stage.gender === gender || stage.gender === 'all') &&
      (stage.country === country || stage.country === 'all')
    );
    setStages(filteredStages);
    setLoading(false);
  };

  const fetchRecurringEvents = async (age, gender, country) => {
    setLoading(true);
    const response = await fetch('./lifeStages.json');
    const data = await response.json();
    const today = new Date();
    const currentMonth = today.getMonth() + 1;

    const events = data.recurringEvents.filter(event =>
      age >= event.minAge &&
      (event.gender === gender || event.gender === 'all') &&
      (event.country === country || event.country === 'all') &&
      event.month === currentMonth
    ).map(event => {
      const eventDate = new Date(today.getFullYear(), event.month - 1, event.day);
      return {
        ...event,
        date: eventDate
      };
    });

    setRecurringEvents(events);
    setLoading(false);
  };

  const fetchOneTimeEvents = async (age) => {
    const response = await fetch('./lifeStages.json');
    const data = await response.json();
    const filteredEvents = data.oneTimeEvents.filter(event => event.minAge === age);
    setOneTimeEvents(filteredEvents);
  };

  return (
    <div className="container mx-auto p-2">
      {loading ? (
        <div>Chargement...</div>
      ) : (
        <>
          {isFormVisible && (
            <div className="intro p-4 rounded-md mb-4">
              <h1 className="text-2xl font-bold mb-2">Bienvenue sur Life Stages</h1>
              <p className="mb-4">Cette application vous aide à suivre les étapes importantes de votre vie en fonction de votre âge, de votre sexe et de votre pays. Remplissez le formulaire ci-dessous pour commencer.</p>
            </div>
          )}
          {isFormVisible ? (
            <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto p-4 rounded-md">
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor="birthdate">
                  <FontAwesomeIcon icon={faBirthdayCake} /> Date de naissance
                  </label>
                  <input
                    type="date"
                    name="birthdate"
                    id="birthdate"
                    value={birthdate}
                    onChange={(e) => setBirthdate(e.target.value)}
                    className="appearance-none block w-full border dark:bg-gray-700 dark:text-white dark:border-gray-600 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    required
                  />
                  {errors.birthdate && <p className="text-red-500">{errors.birthdate}</p>}
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor="gender">
                  <FontAwesomeIcon icon={faVenusMars} /> Sexe
                  </label>
                  <select
                    name="gender"
                    id="gender"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    className="block w-full dark:bg-gray-700 dark:text-white dark:border-gray-600 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  >
                    <option value="all">Tous les genres</option>
                    <option value="male">Masculin</option>
                    <option value="female">Féminin</option>
                  </select>
                  {errors.gender && <p className="text-red-500">{errors.gender}</p>}
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor="country">
                  <FontAwesomeIcon icon={faGlobe} /> Pays
                  </label>
                  <select
                    name="country"
                    id="country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    className="block w-full dark:bg-gray-700 dark:text-white dark:border-gray-600 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option value="all">Tous les pays</option>
                    <option value="france">France</option>
                    <option value="usa">USA</option>
                  </select>
                  {errors.country && <p className="text-red-500">{errors.country}</p>}
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <button type="submit" className="w-full bg-blue-500 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline">
                    <FontAwesomeIcon icon={faPaperPlane} /> Soumettre
                  </button>
                </div>
              </div>
            </form>
          ) : (
            <div className="flex justify-center space-x-4">
              {isFormVisible ? (
                <button
                  onClick={() => setIsFormVisible(!isFormVisible)}
                  className="bg-blue-500 text-white rounded p-2 m-2 flex items-center"
                >
                  <FontAwesomeIcon icon={faEdit} className="mr-2" />
                  Masquer le formulaire
                </button>
              ) : (
                <button
                  onClick={() => setIsFormVisible(!isFormVisible)}
                  className="bg-blue-500 text-white rounded p-2 m-2 flex items-center"
                >
                  <FontAwesomeIcon icon={faEdit} className="mr-2" />
                  Modifier les informations
                </button>
              )}
              <button
                onClick={handleReset}
                className="bg-red-500 text-white rounded p-2 m-2"
              >
                <FontAwesomeIcon icon={faTrash} /> Réinitialiser les informations
              </button>
            </div>
          )}
          {age !== null && (
            <div>
              <h2 className="text-xl font-bold">Vous avez {age} ans</h2>
              <div>
                {stages.map((stage, index) => (
                  <div key={index} className="border p-4 m-2 rounded">
                    <h3 className="text-lg font-bold">{stage.title}</h3>
                    <p>{stage.description}</p>
                    {stage.link && (
                      <a href={stage.link} target="_blank" rel="noopener noreferrer">
                        <button className="bg-blue-500 text-white rounded p-2 mt-2">
                          <FontAwesomeIcon icon={faPlus} />  Plus d'infos
                        </button>
                      </a>
                    )}
                  </div>
                ))}
              </div>
              <div>
                <h2 className="text-xl font-bold mt-4">Événements récurrents ce mois-ci</h2>
                {recurringEvents.length > 0 ? (
                  recurringEvents.map((event, index) => (
                    <div key={index} className="border p-4 m-2 rounded">
                      <h3 className="text-lg font-bold">{event.title}</h3>
                      <p>{event.description}</p>
                      {event.date && <p>Date approximative: {event.date.toLocaleDateString()}</p>}
                      {event.link && (
                        <a href={event.link} target="_blank" rel="noopener noreferrer">
                          <button className="bg-blue-500 text-white rounded p-2 mt-2">
                            <FontAwesomeIcon icon={faFolderOpen} />  Voir la démarche 
                          </button>
                        </a>
                      )}
                    </div>
                  ))
                ) : (
                  <p>Aucun événement récurrent ce mois-ci.</p>
                )}
              </div>
              <div>
                <h2 className="text-xl font-bold mt-4">Événements ponctuels</h2>
                {oneTimeEvents.length > 0 ? (
                  oneTimeEvents.map((event, index) => (
                    <div key={index} className="border p-4 m-2 rounded">
                      <h3 className="text-lg font-bold">{event.title}</h3>
                      <p>{event.description}</p>
                      {event.link && (
                        <a href={event.link} target="_blank" rel="noopener noreferrer">
                          <button className="bg-blue-500 text-white rounded p-2 mt-2">
                            <FontAwesomeIcon icon={faPlug} />  On vous aide ?
                          </button>
                        </a>
                      )}
                    </div>
                  ))
                ) : (
                  <p>Aucun événement ponctuel à afficher.</p>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LifeStages;
